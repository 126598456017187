/*
    Use this to override react-big-calendar styles.
    Default styles are at:  'react-big-calendar/lib/css/react-big-calendar.css'
*/
.rbc-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .rbc-toolbar .rbc-toolbar-label {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    padding: 0 10px;
    text-align: center;
  }
  .rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
  }
  .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
    color: #373a3c;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }
  .rbc-toolbar button:focus {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  .rbc-toolbar button:hover {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  /* .rbc-day-bg {
    background-color: #DAF1E9;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .rbc-off-range {
    color: #999999;
  }
  
  .rbc-off-range-bg {
    background: #e6e6e6;
  }
   */

  .rbc-event {
    background-color: #fff;
    padding: 0;
  }