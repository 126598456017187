#user-search-control {
    z-index: 99;
    padding: 4px;
    background: grey;
}

.rbt {
    border-width: 1px;
	padding: 4px;
    border-radius: 4px;
}