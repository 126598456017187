/* .chakra-stack span {
    background-color: #D9ADEB;
}

.chakra-switch__thumb {
    background-color: white;
}

.chakra-stack .chakra-button {
    background-color: #D9ADEB;
    margin-left: 6px;
} */

/* .chakra-stack input {
    height: 0;
} */

button[aria-label="toggle menu"] {
    color: white;
}

button[aria-label="clear all selected"] {
    color: white;
    margin-right: 6px;
}

span[role="listitem"] {
    background: #B3CFE9;
    color: black;
}